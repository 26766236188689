import PortableTextPlus from "@/components/portable-text-plus";
import classNames from "classnames";
import SegmentContainer from "@/components/segments/segment-container";
import CallToActionButton from "@/components/call-to-action-button";

export default function TwoColumnsTwoBlocks({ segment, ...props }) {
  const [blockOne, blockTwo] = segment.blocks;

  const columnWidthClasses = {
    "fifty-fifty": ["w-full sm:w-1/2", "w-full sm:w-1/2"],
    "one-third-two-thirds": ["w-full sm:w-1/3", "w-full sm:w-2/3"],
    "two-thirds-one-third": ["w-full sm:w-2/3", "w-full sm:w-1/3"],
    "golden-ratio-large-small": ["w-full sm:w-[61.8%]", "w-full sm:w-[38.2%]"],
    "golden-ratio-small-large": ["w-full sm:w-[38.2%]", "w-full sm:w-[61.8%]"],
  };
  const [blockOneWidthClass, blockTwoWidthClass] =
    columnWidthClasses[segment.twoBlocksColumnWidths];

  const getVerticalAlignmentClass = (block) => {
    if (block.verticalAlignment === "top") {
      return "items-start";
    } else if (block.verticalAlignment === "center") {
      return "items-center";
    } else if (block.verticalAlignment === "bottom") {
      return "items-end";
    }
  };

  return (
    <SegmentContainer
      className={props.paddingClasses}
      style={{ ...props.bgImageStyle, ...props.bgColorStyle }}
    >
      <div
        className={classNames(
          props.widthClasses,
          "relative flex flex-wrap sm:flex-row",
          segment.reverseDirectionInMobile ? "flex-col-reverse" : "",
          "gap-y-10 justify-between text-center sm:text-left"
        )}
      >
        <div
          className={classNames(
            blockOneWidthClass,
            "grid",
            getVerticalAlignmentClass(blockOne)
          )}
        >
          <div className="sm:pr-[6%] space-y-8">
            {blockOne.heading && (
              <div
                className={classNames(
                  "leading-snug font-semibold text-black whitespace-pre-line",
                  props.headingSizeClasses[blockOne.headingSize] ||
                    "text-2xl md:text-3xl"
                )}
              >
                <PortableTextPlus value={blockOne.heading} />
              </div>
            )}
            {blockOne.content && (
              <div className="sm:text-lg prose marker:text-subheading-gray marker:text-sm">
                <PortableTextPlus value={blockOne.content} />
              </div>
            )}
            {blockOne.callToActions && (
              <div className="pb-8 flex flex-wrap gap-4 justify-center sm:justify-start">
                {blockOne.callToActions.map((callToAction) => (
                  <CallToActionButton
                    className="w-64 lg:w-56"
                    key={callToAction._key}
                    href={callToAction.url}
                    {...callToAction}
                  >
                    {callToAction.title}
                  </CallToActionButton>
                ))}
              </div>
            )}
          </div>
        </div>
        <div
          className={classNames(
            blockTwoWidthClass,
            segment.isRightBlockLower ? "sm:mt-40" : "sm:mt-0",
            "grid",
            getVerticalAlignmentClass(blockTwo)
          )}
        >
          <div className="sm:pl-[6%] space-y-8">
            {blockTwo.heading && (
              <div
                className={classNames(
                  "leading-snug font-semibold text-black whitespace-pre-line",
                  props.headingSizeClasses[blockTwo.headingSize] ||
                    "text-2xl md:text-3xl"
                )}
              >
                <PortableTextPlus value={blockTwo.heading} />
              </div>
            )}
            {blockTwo.content && (
              <div className="sm:text-lg prose marker:text-subheading-gray marker:text-sm">
                <PortableTextPlus value={blockTwo.content} />
              </div>
            )}
            {blockTwo.callToActions && (
              <div className="pb-8 flex flex-wrap gap-4 justify-center sm:justify-start">
                {blockTwo.callToActions.map((callToAction) => (
                  <CallToActionButton
                    className="w-64 lg:w-56"
                    key={callToAction._key}
                    href={callToAction.url}
                    {...callToAction}
                  >
                    {callToAction.title}
                  </CallToActionButton>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </SegmentContainer>
  );
}
